export default function getAlignClassName(alignment) {
  switch (alignment) {
    case 'left':
      return 'strchf-align-left';
    case 'right':
      return 'strchf-align-right';
    default:
      return 'strchf-align-center';
  }
}
