import getTypeClassName from '@/storyDestinations/converter/utils/getTypeClassName';

export default function leadcapture(block) {
  const storyIntegrationId = block.data.storyIntegrationId;
  if (!storyIntegrationId) {
    return { start: '', end: '' };
  }

  const height = block.data.height || 340;
  const iframeSrc = `${window.location.protocol}//${window.location.host}/capture/${storyIntegrationId}`;
  const htmlClasses = getTypeClassName(block.data.type);

  let start = `<figure class="${htmlClasses}"><div class="strchf-embed-container embed-container">`;
  start += `<iframe src="${iframeSrc}" frameborder="0" scrolling="no" sandbox="allow-forms allow-scripts allow-same-origin" width="400" height="${height}" style="max-width: 100%;"></iframe>`;
  start += `</div>`;
  let end = '</figure>';

  if (block.text !== '') {
    start += '<figcaption>';
    end = `</figcaption>${end}`;
  }

  return { start, end };
}
