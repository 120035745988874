import defaultOembed from '@/storyDestinations/converter/blocks/default/oembed/defaultOembed';

export default function convertOembed(block) {
  const { src } = block.data;
  const oembed = block.data.data;

  if (src === '' || (typeof oembed === 'undefined' && src === '')) {
    return { start: '', end: '' };
  }

  switch (block.data.provider) {
    default:
      return defaultOembed(block);
  }
}
