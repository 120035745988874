import classNames from 'classnames';
import getOembedHtml from '@/oembed/utils/getOembedHtml';
import getAlignClassName from '@/storyDestinations/converter/utils/getAlignClassName';
import getSizeClassName from '@/storyDestinations/converter/utils/getSizeClassName';
import getTypeClassName from '@/storyDestinations/converter/utils/getTypeClassName';

export default function defaultOembed(block) {
  const { src, size, type, alignment } = block.data;
  const oembed = block.data.data;

  let oembedHtml = '';
  // legacy video block: only src was set in older editor
  if (typeof oembed === 'undefined') {
    oembedHtml = `<div style="max-width: 100%; position: relative; padding-top: 56.25%;"><iframe width="480" height="270" src="${src}" frameborder="0" allowfullscreen="allowfullscreen" style="position: absolute; top: 0px; left: 0px; width: 100%; height: 100%;"></iframe></div>`;
  } else {
    oembedHtml = getOembedHtml(oembed, true);
  }

  const figureClassName = classNames(
    getTypeClassName(type),
    getSizeClassName(size),
    getAlignClassName(alignment),
  );
  let start = `<figure class="${figureClassName}">`;
  start += `<div class="embed-container">${oembedHtml}</div>`;
  let end = '</figure>';

  if (block.text !== '') {
    start += '<figcaption>';
    end = `</figcaption>${end}`;
  }

  return { start, end };
}
