import {
  IMAGE_SIZE_LARGE_WIDTH,
  IMAGE_SIZE_FULL_WIDTH,
  IMAGE_SIZE_REGULAR_WIDTH,
} from '@/storychief/constants/Constants';

export default function getImageWidth(size) {
  switch (size) {
    case 'large':
      return IMAGE_SIZE_LARGE_WIDTH;
    case 'full':
      return IMAGE_SIZE_FULL_WIDTH;
    case 'regular':
    default:
      return IMAGE_SIZE_REGULAR_WIDTH;
  }
}
