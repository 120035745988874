import classNames from 'classnames';
import getImageHtml from '@/storyDestinations/converter/utils/getImageHtml';
import getAlignClassName from '@/storyDestinations/converter/utils/getAlignClassName';
import getSizeClassName from '@/storyDestinations/converter/utils/getSizeClassName';
import getTypeClassName from '@/storyDestinations/converter/utils/getTypeClassName';
import getAnchorProps from '@/storyDestinations/converter/utils/getAnchorProps';
import stringifyProps from '@/storyDestinations/converter/utils/stringifyProps';

export default function image(block) {
  const data = block.data;
  const { alignment, src, size, href, targetBlank, relNofollow } = block.data;
  let start = '';
  let end = '';

  if (src === '') {
    return { start, end };
  }

  const htmlClasses = classNames(
    getTypeClassName('image'),
    getSizeClassName(size),
    getAlignClassName(alignment),
  );

  start = `<figure class="${htmlClasses}">`;

  if (href && href !== '') {
    const linkProps = getAnchorProps(href, targetBlank, relNofollow);
    start += `<a${stringifyProps(linkProps)}>${getImageHtml(data, { size })}</a>`;
  } else {
    start += getImageHtml(data, { size });
  }

  end = '</figure>';

  if (block.text !== '') {
    start += '<figcaption>';
    end = `</figcaption>${end}`;
  }

  return { start, end };
}
