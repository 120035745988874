import getImageSizeSrc from '@/editor/utils/getImageSizeSrc';
import stringifyProps from '@/storyDestinations/converter/utils/stringifyProps';
import getImageWidth from '@/storyDestinations/converter/utils/getImageWidth';

/**
 * @param {object} params
 * @param {string} params.src
 * @param {string} params.alt
 * @param {number} params.width
 * @param {object} data
 * @returns {string}
 */
export default function getImageHtml({ src, alt, width }, data) {
  const { size, stylesPicture = null, stylesImg = null } = data;
  const mobileSrc = getImageSizeSrc(src, 'regular');
  const mobileRetinaSrc = getImageSizeSrc(src, 'regular', true);
  const desktopSrc = getImageSizeSrc(src, size);
  const desktopRetinaSrc = getImageSizeSrc(src, size, true);
  const imageSrc = getImageSizeSrc(src, size);

  const getHtml = ({ mobileSrcset, desktopSrcset }) => {
    let html = `<picture${stringifyProps({ style: stylesPicture })}>`;
    html += `<source srcset="${mobileSrcset}" media="(max-width: 768px)" />`;
    html += `<source srcset="${desktopSrcset}" media="(min-width: 769px)" />`;
    html += `<img${stringifyProps({
      style: stylesImg,
      alt,
      loading: 'lazy',
    })} src="${imageSrc}" />`;
    html += `</picture>`;

    return html;
  };

  if (width <= getImageWidth(size) * 2) {
    // Return image without retina
    return getHtml({
      mobileSrcset: `${mobileSrc} 1x`,
      desktopSrcset: `${desktopSrc} 1x`,
    });
  }

  // Return image with retina
  return getHtml({
    mobileSrcset: `${mobileSrc} 1x, ${mobileRetinaSrc} 2x`,
    desktopSrcset: `${desktopSrc} 1x, ${desktopRetinaSrc} 2x`,
  });
}
