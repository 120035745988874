import { convertToHTML } from 'draft-convert';
import {
  paragraph,
  headerOne,
  headerTwo,
  headerThree,
  headerFour,
  blockquote,
  codeblock,
  image,
  leadcapture,
  gallery,
  convertOembed,
  divider,
  empty,
  button,
} from '@/storyDestinations/converter/blocks/default';
import { link } from '@/storyDestinations/converter/entities/default';
import { small, strikethrough } from '@/storyDestinations/converter/styles/default';

export default function defaultConverter(contentState) {
  return convertToHTML({
    styleToHTML: (style) => {
      switch (style) {
        case 'SMALL':
          return small();
        case 'STRIKETHROUGH':
          return strikethrough();
        default:
          // Return undefined so defaults will be used for styles we don't define (eg. <strong>).
          return undefined;
      }
    },
    blockToHTML: (block) => {
      switch (block.type) {
        case 'unstyled':
          return paragraph();
        case 'header-one':
          return headerOne();
        case 'header-two':
          return headerTwo(block);
        case 'header-three':
          return headerThree(block);
        case 'header-four':
          return headerFour(block);
        case 'blockquote':
          return blockquote();
        case 'code-block':
          return codeblock();
        case 'section':
        case 'article':
          return empty();
        case 'atomic':
          switch (block.data.type) {
            case 'divider':
              return divider();
            case 'image':
              return image(block);
            case 'gallery':
              return gallery(block);
            case 'embed':
            case 'video':
              return convertOembed(block);
            case 'capture':
              return leadcapture(block);
            case 'button':
              return button(block);
            default:
              // Fallback for block-undefined
              return empty();
          }
        default:
          // Return undefined so defaults will be used for tags we don't define (eg. <li>).
          return undefined;
      }
    },
    entityToHTML: (entity, originalText) => {
      switch (entity.type) {
        case 'LINK':
          return link(entity);
        default:
          return originalText;
      }
    },
  })(contentState);
}
